/* ====== TO TOP ====== */
jQuery(document).ready(function ($) {
	var offset = 100,
		offset_opacity = 1200,
		scroll_top_duration = 700,
		$back_to_top = $(".cd-top");
	$(window).scroll(function () {
		$(this).scrollTop() > offset ? $back_to_top.addClass("cd-is-visible") : $back_to_top.removeClass("cd-is-visible cd-fade-out");
		if ($(this).scrollTop() > offset_opacity) {
			$back_to_top.addClass("cd-fade-out");
		}
	});
	$back_to_top.on("click", function (event) {
		event.preventDefault();
		$("body,html").animate(
			{
				scrollTop: 0,
			},
			scroll_top_duration
		);
	});
});

/* ====== lightbox ====== */
lightbox.option({
	showImageNumberLabel: false,
	disableScrolling: true,
});

/* ====== FLEX SLIDER ====== */
$(".flexslider").flexslider({
	slideshow: true,
	animation: "fade",
	slideshowSpeed: 6000,
	animationLoop: true,
	pauseOnHover: false,
	controlNav: true,
	keyboard: false,
});

/* ====== HP BLOG ====== */
$(".hp_blog").slick({
	infinite: true,
	autoplay: true,
	arrows: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplaySpeed: 7000,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});

/* ====== HP GALLERY ====== */
$(".hp_gallery").slick({
	infinite: true,
	autoplay: true,
	arrows: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	autoplaySpeed: 7000,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				arrows: false,
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});

/* ====== GALLERY ====== */
$(".about_gallery").slick({
	infinite: true,
	autoplay: true,
	arrows: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	autoplaySpeed: 7000,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				arrows: false,
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});

/* ====== GALLERY ====== */
$(".hp_featured").slick({
	infinite: true,
	autoplay: true,
	arrows: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplaySpeed: 7000,
});
